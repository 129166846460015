import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, Button, colors, Alert
} from '@mui/material';
import fototGjat from "../../images/gjat.png"
import fototShkurt from "../../images/validTicketPhoto.png"

const ModalDialog = ({ isOpen, handleModalClose, lang }) => {
    // console.log('dataToModal modal 123 arton', dataToModal)
    // const [delivered, setDelivered] = useState(dataToModal?.delivered);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [openImageDialog, setOpenImageDialog] = useState(false);


    return (

        <Dialog open={isOpen} onClose={handleModalClose} >
            <DialogTitle className=' text-center modalBackground'>
                {lang == 'al' ?
                    "Si të bëni fotografinë e faturës"
                    : "How to take a photo of the receipt"
                }
            </DialogTitle>

            <DialogContent className='modalBackground'>

                <TableContainer style={{ width: '100%', overflowX: 'auto' }} >
                    <Alert severity="warning" className=' d-flex align-items-center ' >
                        {lang == 'al' ?
                            `Numri serik i faturës përbëhet nga kombinimi i numrit serik të kuponit fiskal dhe shifrës "EN-XXXXX"`
                            :
                            `The serial number of the invoice consists of the combination of the serial number of the fiscal receipt and the digit "EN-XXXXX"`
                        }
                    </Alert>

                    <Table >
                        <TableBody className=''>
                            <TableRow className='responsive_class'>
                                <TableCell>
                                    <img
                                        src={fototShkurt}
                                        alt="Ticket Photo"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                    <p className='mt-2 text-center'>
                                        {lang == 'al' ?
                                            "Nëse kuponi përmbanë vetëm produktin CocaCola"
                                            :
                                            "If the coupon contains only Coca Cola item"}</p>
                                </TableCell>
                                <TableCell>
                                    <img
                                        src={fototGjat}
                                        alt="Ticket Photo"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                    <p className='mt-2 text-center'>
                                        {lang == 'al' ?
                                            `Nëse kuponi përmbanë shumë produkte, të siguroheni që të gjitha informatat e nevojshme si "Coca Cola", numri serik dhe shifra "EN-XXXXX" të jenë të dukshme në një foto.`
                                            :
                                            `If the invoice contains multiple products, make sure that all the necessary information such as "Coca Cola", the serial number and the digit "EN-XXXXX" are visible in one picture.`}
                                    </p>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button onClick={handleModalClose} variant="contained" color="error">
                    {lang == 'al' ? "Mbylle" : "Close"}
                </Button>
            </DialogContent>
        </Dialog >
    );
};

export default ModalDialog;
