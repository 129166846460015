import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/home';
import Terms from './components/terms';
import Logo from './images/cc-logo.png';
import { useEffect, useState } from 'react';
import axios from './Axios';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AplicationSucesful from './components/aplicationSucesfull';



function App() {
  const [labels, setLabels] = useState([]);

  const getLabels = () => {
    axios.get('/general/labels').then(data => {
      // console.log('labelsAPP', data.data);
      setLabels(data.data);
      // setTrans(true);
    }).catch(err => {
      console.log('labelserr', err);
    })
  }
  useEffect(() => { getLabels() }, []);

  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home labels={labels} />} />
          <Route exact path="/success" element={<AplicationSucesful labels={labels} />} />
          <Route exact path="/terms" element={<Terms labels={labels} />} />
        </Routes>
      </Router>
      <div className='footer mb-4'>
        <div className='footerSigniture'>©{new Date().getFullYear()} The Coca-Cola Company.</div>
        <div className='footerLogo'><img alt='logo' src={Logo} /></div>
      </div>
    </div>
  );
}

export default App;
