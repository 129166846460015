import React from 'react'
import { useState } from 'react';
import leftImg from "./../images/cc-left.png";
import rightImg from "./../images/cc-right.png";
import {  useLocation } from 'react-router-dom';

const AplicationSucesful = (props) => {
    // const history = useLocation();
    const [lang, setLang] = useState("al");

    const goBackHandler = () => {
        window.history.back();
    };

    const translateKey = (data, lang) => {
        if (lang === "al") {
            // let trans =  labels !== undefined &&  labels.length > 0  && labels?.filter((label) => {return label?.label === data});
            let trans = props?.labels?.filter((label) => {
                return label?.label === data;
            });
            // console.log('translate', trans[0]?.content_al);
            return trans[0]?.content_al;
        } else {
            // let trans =  labels !== undefined &&  labels.length > 0  &&  labels?.filter((label) => {return label?.label === data});
            let trans = props?.labels?.filter((label) => {
                return label?.label === data;
            });
            return trans[0]?.content_en;
        }
    };

    const handleLangChange = (e) => {
        e.preventDefault();
        if (lang === "al") {
            window.localStorage.removeItem("lang");
            setTimeout(() => {
                window.localStorage.setItem("lang", "en");
                setLang("en");
            }, 200);
        } else {
            window.localStorage.removeItem("lang");
            setTimeout(() => {
                window.localStorage.setItem("lang", "al");
                setLang("al");
            }, 200);
        }
    };
    return (

        <>
            <div className=" container langWrapper my-4">
                <button className="form-control " onClick={handleLangChange}>
                    {lang === "en" ? "English" : "Shqip"}
                </button>
                <button className="form-control " onClick={goBackHandler}>
                    Go Back
                </button>
            </div>
            <div class="container success-container  mx-auto">
                <img src={leftImg} alt="" className="leftImg" />

                {lang == "al" ?
                    <div class="alert alert-success">

                        <h3 class="alert-heading text-center mb-3">JU FALEMNDERIT PËR PJESMARRJEN NË COCA COLA NEW YEAR NCP! NË RAST SE JENI FITURES, DO TË NJOFTOHENI PËRMES E-MAIL.</h3>
                        {/* <h4 className='text-center'>Ne dëshirojmë t'ju ofrojmë disa udhëzime thelbësore për të siguruar një proces të qetë të tërheqjes së dhuratave nëse zgjidheni të jeni fitues (në dy hapa të thjeshtë).</h4> */}
                        <hr />
                        {/* <ol>
                            <li>
                            </li> */}
                        <h5 className='text-center'> INFORMACION I RËNDËSISHËM: JU LUTEMI RUANI FATURËN TUAJ PËR 24 ORËT E ARDHSHME, NË RAST SE KENI FITUAR ÇMIMIN KRYESOR.</h5>
                        {/* <li>
                                <h5>Nëse jeni zgjedhur të jeni fitues i çmimeve tona ditore, atëherë çmimi juaj do të dërgohet me postë në adresën tuaj.
                                </h5>
                            </li> */}
                        {/* </ol> */}
                        <hr />

                        <p className='text-center contact_us_info'>Nëse keni ndonjë pyetje, ju lutemi telefononi numrin e qendrës sonë të kontaktit më poshtë <br /> <a className='nav_link' href="tel:+38349745570">+383 (0) 49-745-570</a></p>
                    </div>
                    :
                    <div class="alert alert-success">
                        <h3 class="alert-heading text-center mb-3">THANK YOU FOR PARTICIPATING IN THE COCA COLA NEW YEAR NCP! IF YOU WIN, YOU WILL BE NOTIFIED BY EMAIL.</h3>
                        {/* <h4 className='text-center'>We would like to provide you with some essential instructions to ensure a smooth prize collection process if you're chosen to be a winner (in two easy steps).</h4> */}
                        <hr />
                        {/* <ol>
                            <li>
                                <h5>If you're chosen to be a winner of our main prizes (iPhone 15, Smart TV, or AirPods), your prize will be awarded in person, and you will need to present the physical invoice associated with your entry to our staff. This step is crucial to validate that the information you provided in the submission form matches the details on the physical invoice.
                                </h5>
                            </li>
                            <li> */}
                        <h5>IMPORTANT INFORMATION: PLEASE SAVE YOUR RECEIPT FOR THE NEXT 24 HOURS IN CASE YOU WIN THE GRAND PRIZE.
                        </h5>
                        {/* </li>
                        </ol> */}
                        <hr />

                        <p className='text-center contact_us_info'>If you have any questions, please contact our call center number below <br /> <a className='nav_link' href="tel:+38349745570">+383 (0) 49-745-570</a></p>
                    </div>
                }
                <img src={rightImg} alt="" className="rightImg" />

            </div>
        </>
    )
}

export default AplicationSucesful